import React, { FC } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { ICategoriesNavItemProps } from './models';

const CategoriesNavItem: FC<ICategoriesNavItemProps> = ({ selected, tag: { name, link } }) => {
  return link ? (
    <li>
      <Link to={link?.[0].url} className={cx({ selected })}>
        {name}
      </Link>
    </li>
  ) : null;
};

export default CategoriesNavItem;
