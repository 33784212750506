import React, { FC, ReactElement } from 'react';
import { Element } from 'react-scroll';
import { parseBoolean } from 'utils/parseHelpers';
import ConditionalWrapper from '../ConditionalWrapper/CondidionalWrapper';
import { IBodyStructureElement, IPropsBodyRenderer } from './model';

const BodyRenderer: FC<IPropsBodyRenderer> = (props): ReactElement => {
  const { bodyData, bodyStructure, bodyItemProps } = props;

  return (
    <>
      {bodyData?.length > 0
        ? bodyData.map((bodyItem: IBodyStructureElement, index: number) => {
            const keyId = `${bodyItem.structure}_${index}`;

            return bodyStructure[bodyItem.structure] ? (
              <ConditionalWrapper
                key={keyId}
                condition={parseBoolean(bodyItem.properties?.showInMenu)}
                wrapper={(children) => (
                  <Element name={bodyItem.properties?.anchorName} className="element">
                    {children}
                  </Element>
                )}
              >
                {bodyStructure[bodyItem.structure](
                  {
                    properties: { ...bodyItem.properties, ...bodyItemProps },
                    structure: bodyItem.structure,
                  },
                  keyId
                )}
              </ConditionalWrapper>
            ) : null;
          })
        : null}
    </>
  );
};

export default BodyRenderer;
