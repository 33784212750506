import React, { FC } from 'react';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';
import ControlledCarousel from 'components/ControlledCarousel';
import TestimonialSlide from './TestimonialSlide';
import responsiveObj from './constants';
import './Testimonials.scss';

const Testimonials: FC<ProductPageTypes.ITestimonials> = ({ title, color, slides }) => {
  const sectionCSSClassNames = classnames('testimonials', {
    [`testimonials--bg-${color[0]}`]: color.length,
  });

  return (
    <div className={sectionCSSClassNames} data-testid="testimonials">
      <Container fluid>
        {title ? <h2 className="testimonials__title">{title}</h2> : null}

        <ControlledCarousel
          dotsOutside
          showDots
          showNavigators
          navigatorsOutside
          classes="testimonials__carousel"
          responsiveObj={responsiveObj}
        >
          {slides?.map(({ properties }) => (
            <TestimonialSlide key={properties.description.substr(0, 7)} {...properties} />
          ))}
        </ControlledCarousel>
      </Container>
    </div>
  );
};

export default Testimonials;
