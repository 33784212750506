import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';

import './SevenSymptomsBanner.scss';
import { SevenSymptomsBannerProps } from './model';

const SevenSymptomsBanner: FC<SevenSymptomsBannerProps> = ({
  data: [
    {
      properties: { items, image, cta, ctaAriaLabel },
    },
  ],
}) => {
  const itemsInChunks = useMemo(() => [items.slice(0, 3), items.slice(3, 5), items.slice(5, 7)], [
    items,
  ]);

  return (
    <section className="seven-symptoms-banner" data-testid="SevenSymptomsBanner">
      <Container fluid>
        <div className="seven-symptoms-banner__wrapper">
          <div className="seven-symptoms-banner__item seven-symptoms-banner__item--left">
            {image[0].properties.image?.fallbackUrl ? (
              <GatsbyImage
                className="seven-symptoms-banner__image"
                objectFit="contain"
                fluid={image[0].properties.image}
                alt={image[0].properties.imageAlt}
              />
            ) : null}
          </div>
          <div className="seven-symptoms-banner__item seven-symptoms-banner__item--right">
            <div className="seven-symptoms-banner__keys">
              {itemsInChunks?.map((chunks) => {
                return (
                  <div
                    key={`chunk-${chunks[0].properties.title}`}
                    className="seven-symptoms-banner__chunk"
                  >
                    {chunks.map((item) => {
                      return (
                        <div
                          key={item.properties.title}
                          className={classNames('seven-symptoms-banner__key', {
                            [`seven-symptoms-banner__key--${item.properties.type[0]}`]: item
                              .properties.type[0],
                          })}
                        >
                          {item.properties.title}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Button to={cta[0].url} ariaLabel={ctaAriaLabel} className="seven-symptoms-banner__cta">
          {cta[0].name}
          <IconCustom icon="chevron-right" />
        </Button>
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentSevenSymptomsBanner on TSevenSymptomsBannerStructure {
    properties {
      ctaAriaLabel
      cta {
        name
        url
      }
      image {
        properties {
          image {
            ...FragmentGatsbyImage
          }
          imageAlt
        }
      }
      items {
        properties {
          title
          type
        }
      }
    }
  }
`;

export default SevenSymptomsBanner;
