import React, { FC } from 'react';
import Button from 'components/common/Button';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';
import IconCustom from 'components/common/IconCustom';

const TestimonialSlide: FC<ProductPageTypes.ITestimonialSlideProps> = ({
  image,
  imageAlt,
  description,
  link,
  ariaLink,
}) => (
  <div className="testimonials__slide">
    {image?.fallbackUrl ? (
      <GatsbyImage
        objectFit="cover"
        className="testimonials__slide-image-img"
        fluid={image}
        alt={imageAlt}
      />
    ) : null}
    <div className="testimonials__slide-text">
      <IconCustom icon="quote" />
      <DangerouslySetInnerHtml className="testimonials__slide-description" html={description} />
    </div>
    {link?.length ? (
      <Button ariaLabel={ariaLink} classes="testimonials__btn" to={link[0].url} variant="link">
        <span className="testimonials__btn-text">{link[0].name}</span>
        <IconCustom icon="chevron-right" />
      </Button>
    ) : null}
  </div>
);

export default TestimonialSlide;
