import React, { FC } from 'react';

import GatsbyImage from 'components/common/GatsbyImage';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import { QuizLinkProps } from './model';
import './QuizLink.scss';

const QuizLink: FC<QuizLinkProps> = ({
  linkQuiz: [
    {
      properties: { title, image, link, imageAlt, ariaLabel },
    },
  ],
}) => (
  <div className="quiz-link" data-testid="quiz-link">
    <div className="quiz-link__composition">
      <div className="quiz-link__baloon">
        {title ? <strong className="quiz-link__title">{title}</strong> : null}
        {link?.length ? (
          <Button variant="link" to={link[0].url} ariaLabel={ariaLabel}>
            {link[0].name}
            <IconCustom icon="chevron-right" />
          </Button>
        ) : null}
      </div>
      {image?.fallbackUrl ? (
        <div className="quiz-link__image">
          <GatsbyImage className="quiz-link__image__image-img" fluid={image} alt={imageAlt} />
        </div>
      ) : null}
    </div>
  </div>
);

export default QuizLink;
