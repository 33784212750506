import React, { FC, useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { isBrowser } from 'utils/browser';
import IconCustom from 'components/common/IconCustom';
import { IDropdownCategories } from './models';

import './DropdownCategories.scss';

const DropdownCategories: FC<IDropdownCategories> = ({ tags }) => {
  const [categoriesReady, setCategoriesReady] = useState(false);
  const currentPath = isBrowser() ? window.location.pathname : null;
  const matchedTag = tags?.find((tag) => tag.link?.[0]?.url === currentPath);
  const filteredTags = tags.filter((item) => item.name !== matchedTag?.name);

  useEffect(() => setCategoriesReady(true), []);

  return (
    <Dropdown className="gs-dropdown-categories">
      {filteredTags.length ? (
        <>
          <Dropdown.Toggle variant="primary" size="lg">
            <span>{categoriesReady ? matchedTag.name : null}</span>
            <IconCustom icon="chevron-right" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {filteredTags.map(({ id, name, link, title }) => {
              if (!link) {
                return null;
              }

              return (
                <Dropdown.Item key={id} href={link[0].url}>
                  {title || name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </>
      ) : null}
    </Dropdown>
  );
};

export default DropdownCategories;
